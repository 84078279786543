'use client'

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { ScrollToTop } from '@/components/simple'
import NextLink from 'next/link'
import React from 'react'
import { useTranslations } from 'next-intl'

export default function Error({error}: {
  error: Error & { digest?: string };
}) {
  const t = useTranslations('pages.error')

  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return (
    <main className='flex-grow-1'>
      <section className='pt-150'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 page-content page-height'>
              <div className='box-404'>
                5<span>0</span>0
              </div>
              <p className='big-text text-center p-404'>
                {t.rich(
                  'content', {
                    br: () => <br />,
                    NextLinkMain: (chunks) => <NextLink href='/'>{chunks}</NextLink>,
                    NextLinkCatalog: (chunks) => <NextLink href='/catalog'>{chunks}</NextLink>,
                  },
                )}
              </p>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTop />
    </main>
  )
}
